<template>
  <div class="is-flex is-size-6 mb-5" id="overview">
    <div>
      <p>{{ $t('farm.pond_name') }}</p>
      <p>{{ $t('farm.area') }}</p>
      <p>{{ $t('total') }} {{ $t('stock.actual_stock') }}</p>
      <p>{{ $t('total') }} {{ $t('stock.netto_stock') }}</p>
      <p>{{ $t('stock.source') }}</p>
      <p>{{ $t('stock.density') }}</p>
    </div>
    <div class="ml-6 has-text-weight-bold">
      <p>{{ selectedPond.name }}</p>
      <p>{{ $numberFormat(selectedPond.area) }} m2</p>
      <p v-if="stocks.length">{{ $numberFormat(selectedStock.pl_count) }}</p>
      <p v-if="stocks.length">{{ $numberFormat(selectedStock.netto) }}</p>
      <p v-if="stocks.length">{{ selectedStock.pl_source }}</p>
      <p v-if="stocks.length">
        {{ $number(selectedStock.pl_count / selectedPond.area) }} {{ $t('shrimp') }}/m2</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportOverview",
  props: {
    stocks: Array,
    selectedStock: Object,
    selectedPond: Object,
  }
}
</script>

<style scoped>

</style>