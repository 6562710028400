<template>
  <div id="sampling" v-if="sampling && sampling.length && sampling.find(e=> e.samplings && e.samplings.length)">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/sampling.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">Sampling</p>
    </div>

    <div class="b-table mb-4" v-if="!hideTable">
      <div class="table-wrapper">
        <table class="table is-bordered">
          <tbody>
          <tr class="header-row">
            <th>{{ $t('farm.pond') }}</th>
            <th>{{ $t('date') }}</th>
            <th>DOC</th>
            <th>MBW</th>
            <th>Size</th>
            <th>Density</th>
            <th>SR</th>
            <th>Biomass</th>
            <th>FCR</th>
            <th>ADG</th>
          </tr>
          </tbody>
          <tbody v-for="(item, key) in sampling" :key="key">
          <tr v-for="(sampling, key) in item.samplings" :key="key">
            <td>{{ item.name }}</td>
            <td>{{ $dateFormat(sampling.sampling_date) }}</td>
            <td>{{ docByStock(item.pond_id, sampling.stock_id, sampling.sampling_date) }}</td>
            <td>{{ $numberFormat(sampling.mbw) }} gr</td>
            <td>{{ sampling.size }}</td>
            <td>{{ $numberFormat(sampling.density) }}</td>
            <td>
              <div class="whitespace-nowrap">{{ $numberFormat(sampling.sr) }}%</div>
            </td>
            <td>
              <div class="whitespace-nowrap">{{ $numberFormat(sampling.biomass) }} Kg</div>
            </td>
            <td>
              <div class="whitespace-nowrap">{{ $numberFormat(sampling.fcr) }}</div>
            </td>
            <td>
              <div class="whitespace-nowrap">{{ $numberFormat(sampling.adg) }} gr</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="my-3">
      <div class="columns is-tablet">
        <div class="column is-half-tablet" v-if="chartMbw.series.length">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">MBW</p>
            </header>
            <div class="card-content">
              <apexchart type="area" height="350" :options="chartMbw.options" :series="chartMbw.series"></apexchart>
            </div>
          </div>
        </div>
        <div class="column is-half-tablet" v-if="chartAdg.series.length">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">ADG</p>
            </header>
            <div class="card-content">
              <apexchart type="area" height="350" :options="chartAdg.options" :series="chartAdg.series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ReportSampling",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    sampling: Array,
    stocks: Array,
    diseaseData: Array,
    hideTable: Boolean,
  },
  computed: {
    annotations() {
      if (this.diseaseData && this.diseaseData.length) {
        let annotations = []
        for (let i = 0; i < this.diseaseData.length; i++) {
          let disease = this.diseaseData[i]
          annotations.push({
            x: disease.created_at,
            strokeDashArray: 0,
            borderColor: '#FF4060',
            label: {
              borderColor: '#FF4060',
              style: {
                color: '#fff',
                background: '#FF4060',
              },
              text: disease.disease_name,
            }
          })
        }
        return {
          xaxis: annotations,
        }
      }
      return null
    },

    chartMbw() {
      let stock = this.stocks[0]
      let options = {
        series: this.sampling.filter(e => e.samplings && e.samplings.length > 1).map(e => {
          return {
            name: 'MBW - ' + e.name,
            data: e.samplings.map(e => {
              return {
                x: e.sampling_date,
                y: e.mbw
              }
            }),
          }
        }),
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },

          xaxis: {
            type: 'datetime',
          },
          legend: {
            horizontalAlign: 'center',
            itemMargin: {
              horizontal: 5,
              vertical: 10
            },
          },
          tooltip: {
            x: {
              formatter: (value) => {
                return this.$dateFormat(value, 'DD MMM YYYY') + ' - DOC ' + (stock ? this.$getDOC(stock.stock_date, value) : '-')
              }
            }
          },
        },
      }
      if (this.annotations) options.annotations = this.annotations
      return options
    },

    chartAdg() {
      let stock = this.stocks[0]
      let options = {
        series: this.sampling.filter(e => e.samplings && e.samplings.length > 1).map(e => {
          return {
            name: 'ADG - ' + e.name,
            data: e.samplings.map(e => {
              return {
                x: e.sampling_date,
                y: e.adg
              }
            }),
          }
        }),
        options: {
          chart: {
            type: 'area',
            height: 350,
            zoom: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },

          xaxis: {
            type: 'datetime',
          },
          legend: {
            horizontalAlign: 'center',
            itemMargin: {
              horizontal: 5,
              vertical: 10
            },
          },
          tooltip: {
            x: {
              formatter: (value) => {
                return this.$dateFormat(value, 'DD MMM YYYY') + ' - DOC ' + (stock ? this.$getDOC(stock.stock_date, value) : '-')
              }
            }
          },
        },
      }
      if (this.annotations) options.annotations = this.annotations
      return options
    },
  },
  methods: {
    docByStock(pond_id, stock_id, date) {
      let stock = this.stocks.find(e => e.stock_id === stock_id && e.pond_id === pond_id)

      if (stock) return this.$getDOC(stock.stock_date, date)

      return '-'
    },
  }
}
</script>
