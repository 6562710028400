<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div id="usage">
    <div v-if="usage.length">
      <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
        <img src="/img/assets/use-item.png" class="mr-3" alt style="height: 20px"/>
        <p class="has-text-weight-bold mb-0">{{ $t('warehouse.usage_summary') }}</p>
      </div>

      <div class="is-flex mb-5 is-size-6" v-if="isCycle">
        <div>
          <p style="font-weight: 600">{{ $t('total') }} {{ $t('warehouse.feed_usage') }}</p>
        </div>
        <div class="ml-6 has-text-weight-bold">
          <p>{{ $numberFormat(usage[0].data.filter(e => e.category_id === 5).reduce((a, b) => a + b.qty, 0), 2) }} Kg</p>
        </div>
      </div>

      <div v-for="(item, key) in usage" :key="key" class="mb-4">
        <div class="b-table mb-2">
          <div class="table-wrapper">
            <table class="table is-bordered">
              <tbody>
              <tr class="header-row">
                <th>{{ $t('farm.pond') }}</th>
                <th>{{ $t('warehouse.product_name') }}</th>
                <th>{{ $t('category') }}</th>
                <th>{{ $t('warehouse.qty') }}</th>
              </tr>
              <tr v-for="(data, dataKey) in item.data" :key="dataKey">
                <td>{{ item.pond.name }}</td>
                <td>{{ data.product_name }}</td>
                <td>{{ $t('warehouse.' + data.category_name.toLowerCase()) }}</td>
                <td>{{ $numberFormat(data.qty, 2) }} {{ data.unit }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportUsage",
  props: {
    usage: Array,
    isCycle: Boolean,
  },
}
</script>
