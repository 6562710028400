<template>
  <div id="disease" v-if="disease.length">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/disease.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ $t('disease') }}</p>
    </div>

    <div v-for="(data, key) in disease" :key="`disease-${key}`" class="mb-4">
      <div class="b-table mb-2" v-if="data.data && data.data.length">
        <div class="table-wrapper">
          <table class="table is-bordered">
            <tbody>
            <tr class="header-row">
              <th>{{ $t('farm.pond') }}</th>
              <th>{{ $t('date') }}</th>
              <th>DOC</th>
              <th>{{ $t('disease') }}</th>
            </tr>
            </tbody>
            <tbody>
            <tr v-for="(item, key) in data.data" :key="key">
              <td>{{ item.name }}</td>
              <td>{{ $dateFormat(item.created_at) }}</td>
              <td>{{ docByCycle(item.pond_id, item.cycle_id, item.created_at) }}</td>
              <td>{{ item.disease_name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p class="has-text-centered" v-else>{{ $t('no') }} Data</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportDisease",
  props: {
    disease: Array,
    stocks: Array,
  },
  methods: {
    docByCycle(pond_id, cycle_id, date) {
      let stock = this.stocks.find(e => e.cycle_id === cycle_id && e.pond_id === pond_id)

      if (stock) return this.$getDOC(stock.stock_date, date)

      return '-'
    },
  }
}
</script>
