<template>
  <div class="mb-3 is-flex is-justify-content-space-between is-align-items-center">
    <div>
      <div class="mb-2">
        <img src="/img/assets/sgara-logo.png" class="d-inline-block align-top" alt="Sgara Logo" style="height: 48px">
      </div>
      <p class="mb-0">
        Periode {{ $dateFormat(parseInt(startDate)) }}
        <span v-if="endDate">- {{ $dateFormat(parseInt(endDate)) }}</span>
      </p>
    </div>
    <div class="has-text-right ml-5">
      <p class="mb-1" style="font-size: 1.3rem">Laporan Tambak</p>
      <p class="" style="font-size: 1.6rem">{{ selectedFarm.name }}</p>
      <p class="mt-1 mb-0 text-secondary" style="font-size: .9rem;max-width: 400px" v-if="!isCycle">{{ ponds.map(e => e.name).join(' - ') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportHeader",
  props: {
    selectedFarm: Object,
    ponds: Array,
    startDate: Number,
    endDate: Number,
    isCycle: Boolean,
  }
}
</script>
