<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div id="product">
    <div v-if="product.length">
      <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
        <img src="/img/assets/use-item.png" class="mr-3" alt style="height: 20px"/>
        <p class="has-text-weight-bold mb-0">{{ $t('warehouse.use_item') }}</p>
      </div>

      <template v-if="!hideTable">
        <div v-for="(product_data, key) in product" :key="`product-${key}`" class="mb-4">
          <div
              class="has-text-weight-bold mb-2 pb-1 border-bottom is-flex is-justify-content-space-between is-align-items-center"
              :class="{
        'has-text-danger': (!!isDiseaseAlert(product_data.date) ? isDiseaseAlert(product_data.date).color === 'danger' : false) || (!!isDeathAlert(product_data.date) ? isDeathAlert(product_data.date).color === 'danger' : false),
        'has-text-warning': (!!isDiseaseAlert(product_data.date) ? isDiseaseAlert(product_data.date).color === 'warning' : false) || (!!isDeathAlert(product_data.date) ? isDeathAlert(product_data.date).color === 'warning' : false),
      }">
        <span class="has-text-dark" style="font-size: 18px">
          {{ $dateFormat(product_data.date) }}
          <span v-if="isCycle">- DOC {{ docByCycle(cycle.cycle_id, product_data.date) }}</span>
        </span>
            <div class="is-flex">
              <b-tooltip type="is-dark" position="is-left" v-if="!!isDiseaseAlert(product_data.date)">
                <b-tag rounded
                       :type="!!isDiseaseAlert(product_data.date) ? (isDiseaseAlert(product_data.date).color === 'danger' ? 'is-danger' : 'is-warning') : ''"
                       class="cursor-pointer ml-2">
                  <b-icon icon="information-outline" size="is-small" class="mr-1"/>
                  {{ $t('disease_alert') }}
                </b-tag>
                <template v-slot:content>
            <span class="is-flex is-flex-direction-column has-text-centered has-text-weight-bold">
            <span v-for="(item, key) in isDiseaseAlert(product_data.date).disease" :key="key">{{ item }}</span>
            </span>
                </template>
              </b-tooltip>
              <b-tag rounded
                     :type="!!isDeathAlert(product_data.date) ? (isDeathAlert(product_data.date).color === 'danger' ? 'is-danger' : 'is-warning') : ''"
                     v-if="!!isDeathAlert(product_data.date)"
                     class="cursor-pointer ml-2">
                <b-icon icon="information-outline" size="is-small" class="mr-1"/>
                {{
                  isDeathAlert(product_data.date).color === 'danger' ? isDeathAlert(product_data.date).data : $t('death_alert')
                }}
              </b-tag>
            </div>
          </div>

          <div v-if="product_data.data.data.data.length">
            <div class="b-table mb-2" v-if="product_data.data.data.data.find(e => e.data.length > 0)">
              <div class="table-wrapper">
                <table class="table is-bordered">
                  <tbody>
                  <tr class="header-row">
                    <th rowspan="2" style="width: 100px">{{ $t('farm.pond') }}</th>
                    <th rowspan="2" style="width: 50px">DOC</th>
                    <th rowspan="2">{{ $t('warehouse.feed_name') }}</th>
                    <th :colspan="product_data.data.opts.total_products">{{ $t('warehouse.feed_schedule') }}</th>
                    <th rowspan="2">Total</th>
                    <th rowspan="2">{{ $t('desc') }}</th>
                  </tr>
                  <tr>
                    <th v-for="n in product_data.data.opts.total_products" :key="n">{{ n }}</th>
                  </tr>
                  </tbody>
                  <tbody v-for="(item, key) in product_data.data.data.data" :key="key">
                  <tr v-if="item.data.length > 0" v-for="(product, productKey) in item.data" :key="productKey">
                    <td class="b-table-sticky-column" v-if="productKey === 0" :rowspan="item.data.length">
                      {{ item.pond_name }}
                    </td>
                    <td>
                      <div>
                        {{ docByCycle(product.cycle_id, product_data.date) }}
                      </div>
                    </td>
                    <td class="white-space-no-wrap">{{ product.product_name }}</td>
                    <td v-for="n in product_data.data.opts.total_products" :key="n">
                        <span class="white-space-no-wrap" v-if="product.qty_times[n-1]">
                          {{ $number(product.qty_times[n - 1].qty, 3) }} {{ product.unit }}
                        </span>
                      <span v-else>-</span>
                    </td>
                    <td v-if="productKey === 0" :rowspan="item.data.length">
                      {{ $number(item.total_qty, 3) }}
                    </td>
                    <td>{{ product.desc || '-' }}</td>
                  </tr>
                  </tbody>
                  <tbody v-if="product_data.data.data.data.length">
                  <tr>
                    <td :colspan="3 + product_data.data.opts.total_products">Total {{ $t('warehouse.feed') }}</td>
                    <td colspan="2">{{ product_data.data.data.data.reduce((a, b) => a + b.total_qty, 0) }}</td>
                  </tr>
                  </tbody>
                  <tbody v-if="!product_data.data.data.data.length">
                  <tr>
                    <td colspan="999">
                      <p class="has-text-centered">{{ $t('no') }} Data</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="b-table mb-2"
                 v-if="product_data.data.data.data.find(e => e.probiotics.length && e.probiotics.find(f=>f.qty_used))">
              <div class="table-wrapper">
                <table class="table is-bordered">
                  <tbody>
                  <tr class="header-row">
                    <th rowspan="2" style="width: 100px">{{ $t('farm.pond') }}</th>
                    <th rowspan="2" style="width: 50px">DOC</th>
                    <th :colspan="product_data.data.data.probiotics.length">{{ $t('warehouse.probiotics') }} &
                      {{ $t('warehouse.chemical') }}
                    </th>
                    <th rowspan="2">{{ $t('desc') }}</th>
                  </tr>
                  <tr>
                    <th v-for="(probiotic, probioticKey) in product_data.data.data.probiotics"
                        :key="`prob${probioticKey}`">
                      {{ probiotic.product_name }}
                    </th>
                  </tr>
                  </tbody>
                  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                  <tbody v-for="(item, key) in product_data.data.data.data" :key="key">
                  <tr v-if="item.probiotics.length && item.probiotics.find(e=>e.qty_used)">
                    <td class="b-table-sticky-column">
                      {{ item.pond_name }}
                    </td>
                    <td>
                      <div>{{ docByCycle(item.probiotics.find(e => e.cycle_id).cycle_id, product_data.date) }}</div>
                    </td>
                    <td v-for="(probiotic, probioticKey) in item.probiotics"
                        :key="`used${probioticKey}-${item.pond_id}`">
                      {{ probiotic.qty_used ? ($number(probiotic.qty_used, 3) + ' ' + probiotic.unit) : '-' }}
                    </td>
                    <td>{{ item.probiotics[0].desc || '-' }}</td>
                  </tr>
                  </tbody>
                  <tbody
                      v-if="!product_data.data.data.data.length || !product_data.data.data.data.find(e=>e.probiotics && e.probiotics.find(e=>e.qty_used))">
                  <tr>
                    <td colspan="999">
                      <p class="has-text-centered">{{ $t('no') }} Data</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <p class="has-text-centered" v-else>{{ $t('no') }} Data</p>
        </div>
      </template>

      <div v-for="(chart_data, key) in chart" :key="`product-chart-${key}`" class="avoid-break mb-4">
        <p class="has-text-weight-bold mb-2 pb-1 border-bottom">{{ chart_data.pond_name }}</p>

        <div class="columns mt-2">
          <div class="column pr-0">
            <div class="has-border px-3 py-2">
              <p>{{ $t('warehouse.daily') }}</p>
              <apexchart type="area" height="250" :options="chart_data.daily.options" :series="chart_data.daily.series"/>
              <div class="is-flex is-justify-content-space-between is-size-7 mb-1" v-for="(item, chartKey) in chart_data.daily.series[0].data" :key="chartKey">
                <p>{{ $dateFormat(item.x) }}</p>
                <p>{{ item.y }} Kg</p>
              </div>
            </div>
          </div>
          <div class="column px-0">
            <div class="has-border px-3 py-2">
              <p>{{ $t('warehouse.accumulative') }}</p>
              <apexchart type="area" height="250" :options="chart_data.accumulative.options" :series="chart_data.accumulative.series"/>
              <div class="is-flex is-justify-content-space-between is-size-7 mb-1" v-for="(item, chartKey) in chart_data.accumulative.series[0].data" :key="chartKey">
                <p>{{ $dateFormat(item.x) }}</p>
                <p>{{ item.y }} Kg</p>
              </div>
            </div>
          </div>
          <div class="column pl-0">
            <div class="has-border px-3 py-2">
              <p>Feed Index</p>
              <apexchart type="area" height="250" :options="chart_data.feed_index.options" :series="chart_data.feed_index.series"/>
              <div class="is-flex is-justify-content-space-between is-size-7 mb-1" v-for="(item, chartKey) in chart_data.feed_index.series[0].data" :key="chartKey">
                <p>{{ $dateFormat(item.x) }}</p>
                <p>{{ item.y }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "ReportProducts",
  props: {
    product: Array,
    stocks: Array,
    chart: Array,
    diseaseDate: Array,
    deathDate: Array,
    isCycle: Boolean,
    cycle: Object,
    hideTable: Boolean,
  },
  components: {
    apexchart: VueApexCharts,
  },
  methods: {
    isDiseaseAlert(date) {
      if (this.diseaseDate) {
        let find = this.diseaseDate.find(e => e.date === date)
        if (find) {
          return find
        }
      }
      return false
    },
    isDeathAlert(date) {
      if (this.deathDate) {
        let find = this.deathDate.find(e => e.date === date)
        if (find) {
          return find
        }
      }
      return false
    },
    docByCycle(cycle_id, date) {
      let stock = this.stocks.find(e => e.cycle_id === cycle_id)

      if (stock) return this.$getDOC(stock.stock_date, date)

      return '-'
    },
  }
}
</script>
