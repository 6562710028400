<template>
  <div id="harvest" v-if="harvest.length">
    <div class="is-flex is-align-items-center border-bottom pb-2 mb-3">
      <img src="/img/assets/stock-harvest.png" class="mr-3" alt style="height: 20px"/>
      <p class="has-text-weight-bold mb-0">{{ totalHarvest ? $t('harvest.all') : $t('harvest.harvest') }}</p>
    </div>

    <div v-for="(data, key) in harvest" :key="`harvest-${key}`" class="mb-4">
      <p class="has-text-weight-bold mb-2 pb-1 border-bottom">{{ data.pond.name }}</p>

      <report-overview :stocks="stocks" :selected-stock="getStock(data.pond.pond_id)" :selected-pond="data.pond" v-if="stocks"/>

      <div class="mt-2 pb-2 columns" v-if="isCycle">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="has-text-weight-bold">FCR</p>
              <p class="">{{ $numberFormat(data.summary.fcr, 2) }}</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="has-text-weight-bold">SR</p>
              <p class="">{{ $numberFormat(data.summary.sr * 100, 2) }}%</p>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="has-text-weight-bold">Produktivitas ton/ha (KPI)</p>
              <p class="">{{ $numberFormat(data.summary.kpi_ton, 2) }} ton/ha</p>
            </div>
          </div>
        </div>
      </div>

      <div class="is-flex">
        <div class="b-table is-flex-grow-2 mb-2" v-if="data.data && data.data.length">
          <div class="table-wrapper">
            <table class="table is-bordered">
              <tbody>
              <tr class="header-row">
                <th>{{ $t('harvest.sale') }}</th>
                <th>{{ $t('warehouse.qty') }}</th>
                <th>Size</th>
                <th>Biomass (Kg)</th>
                <!--                <th>Unit</th>-->
                <!--                <th>{{ $t('price') }}</th>-->
                <!--                <th>Total {{ $t('price') }}</th>-->
              </tr>
              </tbody>
              <tbody>
              <tr v-for="(item, key) in data.data" :key="key">
                <td>{{ item.harvest_name }}</td>
                <td><span v-if="item.qty">{{ $numberFormat(item.qty) }}</span></td>
                <td><span v-if="item.size">{{ $numberFormat(item.size) }}</span></td>
                <td><span v-if="item.biomass">{{ $numberFormat(item.biomass) }}</span></td>
                <!--                <td>Kg</td>-->
                <!--                <td>{{ item.price ? $currency(item.price) : '-' }}</td>-->
                <!--                <td>{{ item.total_price ? $currency(item.total_price) : '-' }}</td>-->
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="b-table is-size-6" v-if="!isCycle">
          <div class="table-wrapper">
            <table class="table table-borderless">
              <tbody>
              <tr>
                <th>{{ $t('farm.area') }}</th>
                <td class="has-text-right">{{ data.summary.area }}</td>
              </tr>
              <tr>
                <th>{{ $t('warehouse.feed_usage') }}</th>
                <td class="has-text-right">{{ data.summary.feed }}</td>
              </tr>
              <tr>
                <th>FCR</th>
                <td class="has-text-right">{{ $numberFormat(data.summary.fcr, 7) }}</td>
              </tr>
              <tr>
                <th>{{ $t('stock.stock') }}</th>
                <td class="has-text-right">{{ $numberFormat(data.summary.pl_count) }}</td>
              </tr>
              <tr>
                <th>SR</th>
                <td class="has-text-right">{{ $numberFormat(data.summary.sr * 100) }}%</td>
              </tr>
              <tr>
                <th>KPI</th>
                <td class="has-text-right">{{ $numberFormat(data.summary.kpi) }} Kg/m2</td>
              </tr>
              <tr>
                <th></th>
                <td class="has-text-right">{{ $numberFormat(data.summary.kpi_ton) }} ton/ha</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="b-table ml-auto is-size-6" style="width: 40%;" v-if="totalHarvest">
      <div class="table-wrapper">
        <table class="table table-borderless">
          <tbody>
          <tr v-if="totalHarvest.total_price">
            <th>Total {{ $t('harvest.revenue') }}</th>
            <td class="has-text-right">{{ totalHarvest.total_price ? $currency(totalHarvest.total_price) : '-' }}</td>
          </tr>
          <tr>
            <th>Total Biomass</th>
            <td class="has-text-right">{{ $numberFormat(totalHarvest.biomass, 2) }}</td>
          </tr>
          <tr>
            <th>Total {{ $t('farm.area') }}</th>
            <td class="has-text-right">{{ $numberFormat(totalHarvest.area) }}</td>
          </tr>
          <tr>
            <th>${{ $t('harvest.overall_kpi') }}</th>
            <td class="has-text-right">{{ $numberFormat(totalHarvest.biomass / totalHarvest.area, 2) }} Kg/m2</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ReportOverview from "@/components/Farm/Report/ReportOverview";
export default {
  name: "ReportHarvest",
  components: {ReportOverview},
  props: {
    harvest: Array,
    isCycle: Boolean,
    totalHarvest: Object,
    stocks: Array,
  },
  methods: {
    getStock(pond_id) {
      return this.stocks.find(e => e.pond_id === pond_id)
    },
  }
}
</script>
